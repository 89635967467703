import { CollectionType, DocumentType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import { CollectionData, DocumentData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import params from '~/params/note.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageEspaceCollectionDocumentCreate: FC<
  PageProps & EspaceProps & UserProps
> = ({ espace, params: { espaceId, collectionId }, user }) => {
  const [, setCollection] = useState<DocumentType<CollectionType>>();
  const [model, setModel] = useState<any>();

  useEffect(() => {
    let isMounted = true;
    const collectionData = new CollectionData({
      espaceId: espace.id,
    });

    collectionData
      .findById(collectionId)
      .then(document => {
        if (isMounted) {
          setCollection(document);

          const newModel = new DocumentData({
            ...document,
            params,
            espaceId: espace.id,
            parentCollectionName: 'collections',
            parentId: collectionId,
          });

          setModel(newModel);
        }

        return null;
      })

      .catch(toast.error);

    return () => {
      isMounted = false;
    };
  }, [collectionId]);

  if (!model) {
    return null;
  }

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <Form
          docId={model.getNewDocId()}
          itemPathnamePrefix={`/espaces/${espaceId}/collections/${collectionId}/documents/`}
          model={model}
          name="collection"
          type="create"
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageEspaceCollectionDocumentCreate);
